body {
  padding-bottom: 50px;
}
.navbar {
  -webkit-box-shadow: 1px 1px 2px 1px #ababab;
  box-shadow: 1px 1px 2px 1px #ababab;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #000;
  text-decoration: underline;
}
.w-table-list-item {
  position: relative;
  background-color: #fff6db;
}
.w-table-list-item .loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.w-list-item-buttons {
  border: 1px solid #ffd556;
  border-top: 0;
  margin-top: 8px;
  background-color: #ffe592;
}
.w-list-item-buttons .col {
  margin-top: 0;
}
.table-list-item {
  border: 1px solid #ffd556;
  border-bottom: 1px solid #ffd556;
}
.table-list-item tr {
  cursor: pointer;
}
.table-list-item td {
  position: relative;
}
.table-list-item td:last-child span {
  position: absolute;
  right: 2px;
  top: 0;
  color: rebeccapurple;
  font-size: 1.2em;
}
.table-list-item .ReactVirtualized__Grid {
  width: 100% !important;
  border-top: 1px solid #ffd556;
}
.table-list-item .ReactVirtualized__Table__headerRow,
.table-list-item .ReactVirtualized__Grid__innerScrollContainer {
  width: 100% !important;
  max-width: 100% !important;
}
.table-list-item .ReactVirtualized__Table__row {
  width: 100% !important;
  border-bottom: 1px solid #ffd556;
  cursor: pointer;
}
.table-list-item .ReactVirtualized__Table__headerRow {
  padding: 15px 0 10px 0;
  border-bottom: 1px solid #ffd556;
}
.col-item-id {
  text-align: right;
  width: 76px;
}
.col-item-icon {
  width: 38px;
  text-align: center;
}
.col-form-label {
  font-size: 13px;
}
.w-row-iteminfo .form-group {
  margin-bottom: 0.3rem;
}
.earth-color {
  background: #c50;
  border-color: #c50;
}
.wind-color {
  background: #3c0;
  border-color: #3c0;
}
.poison-color {
  background: #639;
  border-color: #639;
}
.skill-color {
  background: #090;
  border-color: #090;
}
.neutral-color {
  background: #777;
  border-color: #777;
}
.effect-color {
  background: #639;
  border-color: #639;
}
.equipment-color {
  background: #909;
  border-color: #909;
}
.item-color {
  background: #00f;
  border-color: #00f;
}
.color-buttons {
  margin-left: -3px;
  margin-right: -3px;
}
.color-buttons button {
  margin-right: 3px;
  margin-left: 3px;
  margin-bottom: 3px;
}
.ro-item-container {
  font-family: sans-serif;
  font-size: 12px;
  color: #000;
  width: 276px;
  min-height: 113px;
  background-image: url(./images/ro-ui-box.png);
  background-repeat: repeat-y;
  background-position: -556px 0px;
  position: relative;
}
.ro-item-container * {
  padding: 0;
  margin: 0;
}
.ro-item-container img {
  width: 73px;
}
.ro-item-container p {
  font-size: 12px;
  font-family: sans-serif;
  display: block;
  width: 182px;
  max-height: 350px;
  overflow-y: auto;
  padding: 5px 0 5px 3px;
}
.ro-item-container:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: url(./images/ro-ui-box.png) no-repeat -278px 0px;
}
.ro-item-container > .inner {
  position: relative;
  left: 0;
  width: 100%;
  min-height: 120px;
  background: url(./images/ro-ui-collection.png) no-repeat;
}
.ro-item-container > .inner .ro-item-left {
  float: left;
  width: 90px;
  padding-top: 11px;
  padding-left: 10px;
}
.ro-item-container > .inner .ro-item-right {
  float: left;
  margin-top: 7px;
  padding-bottom: 6px;
}
.ro-item-container > .inner .ro-item-right em {
  width: 168px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
a.external-link::after {
  cursor: pointer;
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}
.col-item-preview {
  position: relative;
  border: 1px solid #e1e1e1;
  padding: 5px;
  border-radius: 5px;
}
.col-item-preview a.external-link {
  font-size: 0.8em;
  position: absolute;
  right: 5px;
  top: 0px;
}
.col-item-preview .custom-switch {
  text-align: left !important;
  font-size: 13px;
}
.col-item-preview .custom-control-label {
  padding-top: 2px;
}
.col-preview-vRO .col-item-preview {
  border-color: #ffc107;
  background: #fff6db;
}
.form-group-preview-description p {
  border: 1px solid #e1e1e1;
  padding: 4px 11px;
  background: #fafafa;
}
.nav-tabs-item-desc a {
  font-size: 13px;
}
.w-row-item {
  position: relative;
}
.w-row-item .nav-tabs-item-desc textarea {
  border-top: 0;
}
.btn-close-tab {
  position: absolute;
  right: 5px;
  top: 5px;
}
.list-item-history {
  max-height: 248px;
  overflow: auto;
}
.list-item-history a {
  padding: 0.3rem 0.7rem;
}
.list-item-history .w-history-buttons {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
}
.list-item-history .w-history-buttons button {
  padding: 3px;
  border-radius: 0;
  border: 1px solid #e1e1e1;
}
.list-item-history .w-history-buttons button:hover {
  background-color: #ccc;
}
.list-item-history .list-group-item {
  padding: 0.5rem 0.5rem;
}
.item-navigation {
  position: fixed;
  z-index: 999;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.item-navigation button {
  color: #000;
  background-color: #ffd556;
  border: 1px solid #000;
}
.item-navigation button:nth-child(2) {
  width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.position-relative .loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.script-list-items li a {
  cursor: pointer;
}
.lua-parser {
  tab-size: 2;
}
.c-pointer {
  cursor: pointer;
}
.list-user-items span.badge {
  margin: 0 2px;
}
.offcanvas.offcanvas-start {
  width: 500px;
}
.item-name {
  display: inline-block;
  line-height: 1;
}
.item-name img {
  border: 1px solid #2ba6e1;
  border-radius: 0.25rem;
  padding: 1px;
  margin-right: 3px;
}
.item-name span {
  vertical-align: middle;
  color: #2ba6e1;
}
.item-name span.badge {
  color: #fff;
  margin-right: 3px;
}
/*Monster Info*/
.monster-info {
  width: 80%;
  border: 1px solid #e1e1e1;
  border-top: 0;
}
.monster-info .table th {
  font-size: 14px;
  padding: 2px 10px;
}
.monster-info .table td {
  font-size: 14px;
  padding: 2px 10px;
}
.monster-info .alert {
  font-size: 13px;
}
.monster-info .list-group li:first-child {
  border-top: 0;
}
.monster-info .list-group li:last-child {
  border-bottom: 0;
}
.monster-info .list-group li {
  border-right: 0;
  border-radius: 0;
  border-color: #e9ecef;
}
.monster-info .list-group-item {
  border-right: 0;
}
.monster-info .list-group-item:first-child {
  border-top: 0;
}
.monster-info .list-group-item:last-child {
  border-bottom: 0;
}
.monster-info .list-group-item .col {
  border-right: 1px solid #e9ecef;
}
.monster-info .list-group-item .col:last-child {
  border-right: 0;
}
.monster-info .label {
  font-size: small;
}
.table-monster-drops-band {
  background: #f8f9fa;
  padding: 5px 10px;
  font-size: 0.9em;
  text-align: center;
  font-weight: bold;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
.row-mob-info .monster-title {
  font-size: 14px;
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.row-mob-info code {
  display: inline-block;
}
.row-mob-info .mob-img {
  width: 100%;
  padding: 10px;
  text-align: center;
}
.row-mob-info .mob-img img {
  max-width: 105px;
  height: auto;
}
.row-stats {
  text-align: center;
}
.row-stats .col-stat {
  position: relative;
}
.row-stats .col-stat span:last-child {
  background: #ccc;
}
.row-stats .col-stat span:first-child,
.row-stats .col-stat span:last-child {
  display: block;
}
.row-stats .col-stat span:last-child {
  font-size: small;
}
.form-search-monster {
  height: calc(100vh - 80px);
  padding-right: 15px;
  border-right: 1px solid #e1e1e1;
}
/*End Monster Info*/
.lua-parser textarea {
  border: 1px solid #e1e1e1 !important;
  border-top: 0 !important;
}
.count-script-list .btn-close {
  position: absolute;
  right: 15px;
  top: 5px;
}
.modal-fileShow .form-check {
  font-size: 1rem;
}
.ro-tag {
  cursor: pointer;
}
.ReactTags__selected {
  margin: 0 -2.5px 10px;
}
.ReactTags__selected .ReactTags__tag {
  display: inline-block;
  padding: 0.4em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  background-color: #0d6efd !important;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  margin: 0 2.5px;
}
.ReactTags__selected .ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: #fff;
  margin-left: 5px;
  font-size: 14px;
}
.ReactTags__selected .ReactTags__remove:hover {
  background: #eb7070;
}
.ReactTags__suggestions {
  border: 1px solid #e1e1e1;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  padding: 0 5px;
  margin: 0;
}
.ReactTags__suggestions li {
  padding: 3px 0;
  border-bottom: 1px solid #e1e1e1;
}
.ReactTags__suggestions .ReactTags__activeSuggestion {
  background-color: #ccc;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.editor-code {
  border: 1px solid #e1e1e1;
}
.editor-code textarea {
  display: none !important;
}
.editor-code pre {
  line-height: 1 !important;
}
.monsters-in-map td {
  vertical-align: middle;
}
.w-table-list-item #list-context-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  border: 1px solid #e1e1e1;
  min-width: 100px;
  max-width: 500px;
  background: #fff;
  padding: 5px 10px;
}
.w-table-list-item #list-context-menu button {
  padding: 0px 3px;
  margin: 0 1px;
}
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.spinner-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.w-item-name h3 {
  font-size: 1rem;
  margin-bottom: -5px;
}

.w-item-name code {
  font-size: 0.7rem;
}

.w-item-icon {
  position: relative;
  display: inline-block;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  width: 30px;
  height: 30px;
}

.w-item-icon img {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table-itemMoveInfo {
  width: auto;
  text-align: center;
}

.table-itemMoveInfo th {
  padding-bottom: 3px;
}

.table-itemMoveInfo svg,
.table-itemMoveInfo img {
  width: auto;
  height: 24px;
}

.ro-item-container-flex .ro-item-col-left {
  padding-left: 10px;
  padding-top: 11px;
  padding-right: 10px;
}

.ro-item-container-flex .ro-item-col-right {
  padding-top: 6px;
  padding-bottom: 5px;
}

.ro-item-container-flex p {
  padding-left: 0;
  padding-right: 3px;
}

.w-table-item-drops {
  max-height: 420px;
  overflow-y: auto;
  border: 1px solid #e1e1e1;
}

.w-table-item-drops thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ccc;
}

#single-item-container .tab-content {
  padding-top: 10px;
}

#single-item-container table td {
  vertical-align: middle;
}

.single-item-tabs li button {
  height: 60px;
}

@media screen and (max-width: 767px) {
  .w-table-list-item {
    border-top: 1px solid #ffd556;
    border-bottom: 1px solid #ffd556;
  }

  .w-table-list-item .ReactVirtualized__Grid {
    height: 300px !important;
  }
  .monster-info {
    width: 100%;
  }
  .form-search-monster {
    height: auto;
  }

  .row-mob-info .monster-title {
    position: static;
    left: none;
    transform: none;
    margin-bottom: 15px;
  }
}
